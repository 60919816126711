/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "W październiku 2023 r. zakończyły się prace budowlane związane z zadaniem pod nazwą „Przebudowa wraz z rozbudową drogi wojewódzkiej Nr 254 Brzoza – Łabiszyn – Barcin – Mogilno – Wylatowo (odcinek Brzoza – Łabiszyn). Odcinek I od km 0+069 do km 13+280”."), "\n", React.createElement(_components.p, null, "Inwestycja, na którą podpisano umowę w 2020 roku zrealizowana została w formule „zaprojektuj i wybuduj”. Etap projektowania budowy został zakończony w 2022 r., zaś w 2023 r. uzyskaliśmy decyzję ZRID."), "\n", React.createElement(_components.p, null, "W ramach inwestycji zostały wykonane następujące zadania:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "wzmocnienie konstrukcji jezdni (do parametrów 11,5 tony na oś)"), "\n", React.createElement(_components.li, null, "przebudowa poboczy i istniejących zjazdów do posesji,"), "\n", React.createElement(_components.li, null, "przebudowa skrzyżowań,"), "\n", React.createElement(_components.li, null, "budowa rond w Łabiszynie na połączeniu z drogą wojewódzką nr 246 oraz w Brzozie na skrzyżowaniu z ulicą Powstańców Wielkopolskich,"), "\n", React.createElement(_components.li, null, "przebudowa i budowa chodników na terenach zurbanizowanych,"), "\n", React.createElement(_components.li, null, "budowa ciągów pieszo-rowerowych w Brzozie i Kobylarni oraz zatok autobusowych"), "\n", React.createElement(_components.li, null, "budowa i przebudowa kanalizacji deszczowej i sanitarnej na wybranych odcinkach,"), "\n", React.createElement(_components.li, null, "instalacja elementów bezpieczeństwa ruchu drogowego."), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
